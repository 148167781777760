import { useState } from "react";
// dayjs
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { useNavigate, useParams } from "react-router-dom";
// types
import { IMessageListItem, MessageType } from "../../../../store/messageLists";
import { MessageStatus } from "../../../../types/Messages";
// components
import {
  ButtonV2,
  pluralize,
  Text,
  Tooltip,
  IconButton,
  ActionablePopover,
  nFormatter,
} from "@asayinc/component-library";
import { Stack } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// data
import { useMessageDialogHooks } from "../../../../hooks";
import { CardWrapper, ConditionalLink, StatusBadge } from "../../../Common";
import { generateShortDateString } from "../../../../utils/generateDateString";
import { useAppDispatch } from "../../../../store/hooks";
import { setMessageDrawer } from "../../../../store/messageCompose";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetProxyEventDetailsQuery } from "../../../../store/proxyEvent";
import { isAfterVoteCutoff } from "../../../../pages/ProxyVoting/utils";

dayjs.extend(relativeTime);
dayjs.extend(utc);

interface IPropsNotLoading {
  message: IMessageListItem;
  isLoading?: false;
}

interface IPropsLoading {
  message?: IMessageListItem;
  isLoading: true;
}

// use props so that discriminated union works in TS
const MessageCard = (props: IPropsLoading | IPropsNotLoading) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { proxyEventId } = useParams();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const isDraft = props?.message?.status === MessageStatus.Draft;
  const { scheduleSend, openDeleteDialog, openConfirmEditDialog } =
    useMessageDialogHooks(props?.message?.id, props?.message?.proxyEventId);
  const { data: proxyData, isLoading: isProxyLoading } =
    useGetProxyEventDetailsQuery(proxyEventId || skipToken);

  // disable Edit if after voteCutoff
  const isAfterCutoff =
    !!proxyEventId &&
    !isProxyLoading &&
    isAfterVoteCutoff(proxyData?.voteCutoff);
  const disableEdit = props.isLoading || isAfterCutoff;

  const messageType = props?.message?.type;
  const messageUrl =
    messageType === MessageType.SM
      ? `/messages/${props?.message?.id}/edit`
      : `/vote/${props?.message?.proxyEventId}/messages/${props?.message?.id}/edit`;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleAnchorEl = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      handleClose();
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const openDrawer = () => {
    dispatch(
      setMessageDrawer({
        messageDrawerId: props?.message?.id,
        messageDrawerProxyEventId: props?.message?.proxyEventId,
      })
    );
  };

  const clickEdit = () => {
    if (props?.message?.status === MessageStatus.Scheduled) {
      // show confim dialog if message is scheduled
      openConfirmEditDialog();
    } else {
      navigate(messageUrl);
    }
  };

  const handleDeleteBtnClick = () => {
    handleClose();
    openDeleteDialog();
  };

  const showTooltip = (props?.message?.campaignName?.length || 0) > 30;

  return (
    <CardWrapper cardName="message" sx={{ py: 7 }}>
      <Stack minWidth={340} data-testid={`card-${props?.message?.id}`}>
        {!props.isLoading && (
          <StatusBadge messageStatus={props.message?.status} />
        )}
        <Stack direction="row" justifyContent="space-between" mt={3}>
          <Tooltip
            placement="top"
            title={
              props.isLoading || !showTooltip ? "" : props.message.campaignName
            }
          >
            <Text
              mb={6}
              variant="subtitle1"
              loadingProps={{ sx: { width: "286px" } }}
              sx={{
                width: "286px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {props.isLoading ? undefined : props?.message?.campaignName}
            </Text>
          </Tooltip>
          <ActionablePopover
            anchorEl={anchorEl}
            handleClose={handleClose}
            actions={[
              ...(!isDraft
                ? [
                    {
                      testid: "change-schedule-btn",
                      text: "Change schedule",
                      onClick: scheduleSend,
                    },
                    {
                      testid: "edit-btn",
                      text: "Edit",
                      onClick: clickEdit,
                    },
                  ]
                : []),
              {
                testid: "delete-btn",
                text: "Delete",
                onClick: handleDeleteBtnClick,
              },
            ]}
          />
        </Stack>
        <Stack direction="row" mb={6} gap={8}>
          <Stack flexBasis="100%">
            <Text emphasis="medium" variant="body2" mb={1}>
              {props.isLoading ? undefined : "Estimated Reach"}
            </Text>
            <Text variant="body2">
              {props.isLoading
                ? undefined
                : `${nFormatter(
                    props.message.estimatedReach
                  )} Shareholder${pluralize(
                    "",
                    "s",
                    props.message.estimatedReach
                  )}`}
            </Text>
          </Stack>
          <Stack flexBasis="100%" whiteSpace="nowrap">
            <Text emphasis="medium" variant="body2" mb={1}>
              {props.isLoading
                ? undefined
                : isDraft
                ? "Last edit"
                : "Scheduled for"}
            </Text>
            <Text variant="body2">
              {props.isLoading
                ? undefined
                : isDraft
                ? dayjs.utc(props.message.lastEdited).local().fromNow()
                : generateShortDateString(props.message)}
            </Text>
          </Stack>
        </Stack>
        <Stack>
          <Stack direction="row" gap={3}>
            {isDraft ? (
              <Tooltip
                title={isAfterCutoff ? "This event has closed" : undefined}
              >
                <ConditionalLink
                  to={messageUrl}
                  condition={!disableEdit}
                  style={{ textDecoration: "none" }}
                >
                  <ButtonV2
                    loading={props.isLoading}
                    disabled={props.isLoading || disableEdit}
                    size="small"
                    variant="secondary"
                  >
                    Edit
                  </ButtonV2>
                </ConditionalLink>
              </Tooltip>
            ) : (
              <ButtonV2
                loading={props.isLoading}
                disabled={props.isLoading}
                onClick={openDrawer}
                size="small"
                variant="secondary"
              >
                View details
              </ButtonV2>
            )}

            <IconButton
              variant="ctaAlt"
              loading={props.isLoading}
              onClick={toggleAnchorEl}
              size="medium"
            >
              <MoreVertIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </CardWrapper>
  );
};

export default MessageCard;

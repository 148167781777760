import { IFilterAutocompleteValue } from "@asayinc/component-library";
import { router } from "../../router";
import { CustomAxiosError } from "../../types/Common";
import { Event, EventStatusOptions, IDraftEvent } from "../../types/Events";
import { setSnackbar } from "../common";
import { IFilterEventOption } from "../messageCompose";
import { setDraftCreated, setPublishDialog } from "./slice";
import { rootApi } from "../rootApi";

// Event Api
export const eventApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * GET SINGLE Event
     * */
    getEvent: builder.query<Event, string>({
      query: (slug) => `qa-events/${slug}/`,
      async onQueryStarted(slug, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          const err = error as CustomAxiosError;
          if (err?.error?.data?.detail === "Not found.") {
            dispatch(
              setSnackbar({
                message: `No event exists under ${slug}.`,
                open: true,
                severity: "error",
              })
            );
            router.navigate("/qa/create", { replace: true });
          } else {
            dispatch(
              setSnackbar({
                message: `${err.error.status} ERROR: ${err.error.statusText}`,
                open: true,
                severity: "error",
              })
            );
          }
        }
      },
      transformResponse: (response: Event) => {
        response.leaders = [...(response.leaders || [])].sort((a, b) =>
          a.order > b.order ? 1 : -1
        );
        if (response.faqs) {
          response.faqs = [...response.faqs].sort((a, b) =>
            a.order > b.order ? 1 : -1
          );
        }

        return response;
      },
      providesTags: (_response, _err, arg) => [
        "Event",
        { type: "Event", id: arg },
      ],
    }),
    /**
     * Create Event and Draft Event
     * */
    createEvent: builder.mutation<Event, IDraftEvent>({
      query: (event) => ({
        url: "qa-events/",
        method: "POST",
        body: event,
      }),
      async onQueryStarted({ title, slug }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          router.navigate(`/qa/${slug}/edit`, { replace: true });
        } catch (error) {
          const err = error as CustomAxiosError;
          if (err?.error?.status === 409) {
            dispatch(
              setSnackbar({
                message: `Event name ${title} already exits. Please update selections.`,
                open: true,
                severity: "error",
              })
            );
          } else {
            dispatch(
              setSnackbar({
                message: `Failed to create event, please try again.`,
                open: true,
                severity: "error",
              })
            );
          }
        }
      },
      invalidatesTags: ["Event", "AllEvents"],
    }),
    /**
     * Update Event
     * */
    updateEvent: builder.mutation<Event, Partial<Event>>({
      query: ({ slug, ...event }) => ({
        url: `qa-events/${slug}/`,
        method: "PATCH",
        body: event,
      }),
      async onQueryStarted(
        { slug, status, submit },
        { dispatch, queryFulfilled }
      ) {
        try {
          //const { data: updatedDraft } = await queryFulfilled;
          await queryFulfilled;
          // `onSuccess` side-effect
          if (submit) {
            if (status === EventStatusOptions.DRAFT) {
              dispatch(setDraftCreated(true));
            }
            dispatch(
              setPublishDialog({
                type:
                  status === EventStatusOptions.DRAFT ? "draft" : "submitted",
                eventSlug: slug as string,
              })
            );
          }
        } catch {}
      },
      invalidatesTags: ["Event", "AllEvents"],
    }),
    /**
     * Delete Draft Event
     * */
    deleteDraftEvent: builder.mutation<IDraftEvent, string>({
      query: (slug) => ({
        url: `qa-events/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: (_response, _err, slug) => [
        "AllEvents",
        { type: "Event", id: slug },
      ],
    }),
    /**
     * GET all qa events for filtering
     * */
    getEventFilterOptions: builder.query<IFilterAutocompleteValue[], void>({
      query: () => "messages/filters/qa-events/",
      providesTags: ["AllEvents"],
      transformResponse: (response: IFilterEventOption[]) => {
        return response.map((r) => ({
          id: r.slug,
          name: r.title,
        }));
      },
    }),
    /**
     * Delete CSV
     * */
    deleteCsv: builder.mutation<IDraftEvent, string>({
      query: (slug) => ({
        url: `qa-events/${slug}/audience/`,
        method: "DELETE",
      }),
      invalidatesTags: (_response, _err, slug) => [{ type: "Event", id: slug }],
    }),
  }),
});

export const {
  useDeleteCsvMutation,
  useGetEventFilterOptionsQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
  useDeleteDraftEventMutation,
  useGetEventQuery,
} = eventApi;

import { Stack } from "@mui/material";
import {
  ChipV2 as Chip,
  Text,
  TooltipInfoIcon,
} from "@asayinc/component-library";
import { CardWrapper } from "../../../../../../../components/Common";
import { TrendGraph } from "../";
// redux / data
import { useGraphData } from "./useGraphData";
import { useIsShareholdersEnabled } from "../../../../../useIsShareholdersEnabled";

const TrendGraphCard = () => {
  const { isFeatureEnabled } = useIsShareholdersEnabled();
  const { isFetching, graphData, timeframe, setTimeframe, graphEvents } =
    useGraphData(isFeatureEnabled);

  const setQuarter = () => {
    setTimeframe("quarter");
  };

  const setMonth = () => {
    setTimeframe("month");
  };

  if (!isFeatureEnabled) {
    return null;
  }

  return (
    <CardWrapper
      cardName="shareholder-trend-graph"
      sx={{ width: "100%", minWidth: 720, mb: 12 }}
    >
      <Stack data-testid={isFetching ? "graph-loading" : "graph-loaded"}>
        <Stack direction="row" mb={2}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Text variant="subtitle2" loadingProps={{ sx: { width: 100 } }}>
              {isFetching ? undefined : "Shares over time"}
            </Text>
            <TooltipInfoIcon
              title={`Tracks top shareholder groups from the beginning of the ${timeframe}`}
              iconColor="black"
            />
          </Stack>
          <Stack direction="row" ml="auto" gap={2.5}>
            <Chip
              data-testid="quarter-chip"
              onClick={setQuarter}
              label="This quarter"
              size="small"
              selected={timeframe === "quarter"}
            />
            <Chip
              data-testid="month-chip"
              onClick={setMonth}
              label="This month"
              size="small"
              selected={timeframe === "month"}
            />
          </Stack>
        </Stack>
        <TrendGraph
          graphData={graphData}
          isLoading={isFetching}
          timeframe={timeframe}
          graphEvents={graphEvents}
        />
      </Stack>
    </CardWrapper>
  );
};

export default TrendGraphCard;

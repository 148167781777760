import { useEffect, useState, useCallback, useMemo } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
// Material
import { Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FilterListIcon from "@mui/icons-material/FilterList";
// Say
import { pluralize, TableV2 as Table, Text } from "@asayinc/component-library";
// Redux/Data
import { useAppDispatch } from "../../../../../../../store/hooks";
import {
  initialState,
  useGetQuestionsQuery,
  questionsApi,
} from "../../../../../../../store/questions";
import { usePatchQuestionMutation } from "../../../../../../../store/question";
import { useQuestionsPageData } from "../../../../../hooks";
// constants
import {
  ROW_OPTIONS,
  SORT,
  SORT_SYMBOL,
  URL_PARAMS,
  DELETED_USER_ID,
} from "../../../../../../../constants";
import { COLUMNS } from "./constants";
// components
import { ExportQuestions } from "../../../../../components";
import { QuestionsAddTagsDialog } from "../../../../../../../components/Common";
import { FilterList } from "../";
// utils
import { useTableSearchParams } from "../../../../../../../hooks";
import { paginationAction, track } from "../../../../../../../analytics";
import {
  trackOrderingChange,
  trackStarRow,
  trackToggleAllRows,
  trackOpenDialog,
  getNoResultsProps,
} from "../../../../../utils";
import { useSearchFns } from "../../../../../../../hooks/useSearchFns";
import { getColumnData, getRowData } from "./factories";
import { useCurrentlySelectedPageIds } from "../../../../../../../hooks/useCurrentlySelectedPageIds";
// types
import { QuestionsApiResponse } from "../../../../../../../store/questions";
import { TableEventData } from "../../../../../../../types/Table";
import { QuestionTableEventData } from "./types";
import { useSuccessErrorSnacks } from "../../../../../../../hooks/useSuccessErrorSnacks";

const QuestionsTable = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  // hack to invalidate questions list when something has been starred
  const [hasStarred, setHasStarred] = useState(false);
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  // data
  const { title } = useQuestionsPageData();
  const { eventSlug } = useParams() as { eventSlug: string };

  const [patchQuestion] = usePatchQuestionMutation();

  /**
   * callback for tracking when sort occurs
   */
  const sortCallback = (sortValue: string) => {
    trackOrderingChange(sortValue, rawApiObject, eventSlug);
  };

  /**
   * callback for tracking pageChange
   */
  const pageChangeCallback = (newPage: number) => {
    paginationAction("Page", "Questions", newPage, count, apiParams);
  };

  /**
   * callback for tracking row change
   */
  const rowChangeCallback = (newRows: number) => {
    paginationAction("Row", "Questions", newRows, count, apiParams);
  };

  const {
    paramObj,
    rawApiObject,
    getSort,
    sortFn,
    handlePageChange,
    handleRowsChange,
  } = useTableSearchParams({
    sortCallback,
    defaultOrdering: `${SORT_SYMBOL.desc}${SORT.votes}`,
    pageChangeCallback,
    rowChangeCallback,
  });

  const searchFns = useSearchFns(URL_PARAMS.search);

  // rename params to what questions uses
  const apiParams = {
    query: paramObj.search,
    page_size: paramObj.limit,
    page: paramObj.page,
    type: paramObj.type,
    tag_ids: paramObj.tags,
    filter: paramObj.filter,
    ordering: paramObj.ordering,
    shares_upvoted_min: paramObj.sharesUpvotedMin,
    shares_upvoted_max: paramObj.sharesUpvotedMax,
    upvote_count_min: paramObj.upvoteCountMin,
    upvote_count_max: paramObj.upvoteCountMax,
    submitted_before: paramObj.submittedBefore,
    submitted_after: paramObj.submittedAfter,
    is_answered: paramObj.isAnswered,
    shareholder_tag_ids: paramObj.shareholderTagIds,
    shareholders_not_tagged: paramObj.shareholdersNotTagged,
    specific_category_ids: paramObj.specificCategoryIds,
  };

  const {
    data: questionsData = initialState,
    isFetching,
    isLoading,
  } = useGetQuestionsQuery({
    eventSlug,
    params: apiParams,
  });

  const {
    results: questions,
    ids,
    count,
  } = questionsData as QuestionsApiResponse;

  // selected on page and current page result ids
  const selectedIdsOnPage = useCurrentlySelectedPageIds(ids, selectedIds);
  // controls which dialog to display
  const [dialog, setDialog] = useState<
    "singleTag" | "bulkTag" | "download" | ""
  >("");
  const theme = useTheme();

  /**
   * invalidate questions cache if something has been starred on this page
   */
  useEffect(() => {
    if (hasStarred) {
      dispatch(questionsApi.util.invalidateTags(["Questions"]));
      setHasStarred(false);
    }
  }, [
    apiParams.type,
    apiParams.ordering,
    apiParams.tag_ids,
    apiParams.query,
    apiParams.filter,
    apiParams.page_size,
  ]);

  /**
   * select all rows
   */
  const handleToggleAllRows = () => {
    if (selectedIdsOnPage.length) {
      setSelectedIds(
        selectedIds.filter((id) => !selectedIdsOnPage.includes(id))
      );
      trackToggleAllRows("deselect", apiParams, eventSlug);
    } else {
      setSelectedIds([...selectedIds, ...ids]);
      trackToggleAllRows("select", apiParams, eventSlug);
    }
  };

  /**
   * Track route to question page
   */
  const trackRouteToQuestion = useCallback(
    (id: string) => {
      track({ name: "Question Drawer Opened", slug: eventSlug, id });
    },
    [eventSlug]
  );

  /**
   * Route to question page
   * @param data TableEventData
   */
  const goToQuestion = useCallback(
    (data: unknown) => {
      const { id } = data as TableEventData;
      trackRouteToQuestion(id);
      searchParams.set(URL_PARAMS.qid, id);
      searchParams.set(URL_PARAMS.eid, eventSlug);
      setSearchParams(searchParams, { state: { goBackText: title } });
    },
    [search, title, trackRouteToQuestion]
  );

  /**
   * Route to question page
   * @param data TableEventData
   */
  const goToShareholder = useCallback(
    (data: unknown) => {
      const { globalUserId, globalShareholderId } =
        data as QuestionTableEventData;
      if (globalUserId === DELETED_USER_ID) {
        return;
      }
      searchParams.set(URL_PARAMS.sid, globalShareholderId);
      setSearchParams(searchParams, { state: { goBackText: "Q&A" } });
    },
    [search, title, trackRouteToQuestion, searchParams]
  );

  /**
   * Select row
   * add/remove from list of selected rows
   */
  const checkRow = useCallback(
    (data: unknown) => {
      const { id, checked } = data as TableEventData;
      setSelectedIds((curIds) => {
        if (curIds.includes(id)) {
          return curIds.filter((itm) => id !== itm);
        } else {
          return [...curIds, id];
        }
      });
      track({
        name: "Check Action",
        slug: eventSlug,
        questionId: id,
        sortingApplied: Boolean(apiParams.ordering),
        searchApplied: Boolean(apiParams.query),
        action: checked ? "deselect" : "select",
      });
    },
    [eventSlug, apiParams]
  );

  /**
   * Disptch questionPatch request
   * Which, manually sets question to starred and runs api in background and validates with response
   */
  const starRow = useCallback(
    (data: unknown) => {
      setHasStarred(true);
      const { isSelectedByIssuer, id } = data as TableEventData;
      patchQuestion({
        questionId: id,
        eventSlug,
        patchData: { isSelectedByIssuer: !isSelectedByIssuer },
        params: apiParams,
      });
      trackStarRow(id, isSelectedByIssuer, eventSlug);
    },
    [eventSlug, apiParams]
  );

  /**
   * Column data for table
   */
  const columnData = useMemo(
    () => getColumnData({ getSort, goToQuestion, sortFn, goToShareholder }),
    [getSort, goToQuestion, sortFn, goToShareholder]
  );

  /**
   * Route to note section in question page
   * @param data TableEventData
   */
  const goToNote = useCallback(
    (data: unknown) => {
      const { id } = data as TableEventData;
      trackRouteToQuestion(id);
      searchParams.set(URL_PARAMS.qid, id);
      searchParams.set(URL_PARAMS.eid, eventSlug);
      setSearchParams(searchParams, { state: { goBackText: title } });
      // scroll notes element into view
      setTimeout(() => {
        const notesElement = document.getElementById("notes");
        notesElement?.scrollIntoView();
      }, 100);
    },
    [search, title, trackRouteToQuestion]
  );

  /**
   * Opens tag dialog for single question
   * @param data TableEventData
   */
  const openTagDialog = useCallback(
    (data: unknown) => {
      const { id } = data as TableEventData;
      setSelectedIds([id]);
      openDialog("singleTag");
    },
    [eventSlug, apiParams]
  );

  const drawerQuestionId = searchParams.get(URL_PARAMS.qid);
  const drawerShareholderId = searchParams.get(URL_PARAMS.sid);

  /**
   * Each rows specific data for table
   */
  const rowData = getRowData({
    questions: questions,
    primaryColor: theme.palette.primary.main,
    selectedIds,
    checkRow,
    starRow,
    goToNote,
    openTagDialog,
    drawerQuestionId,
    drawerShareholderId,
  });

  /**
   * Toggle the dialog for exporting or tagging questions in bulk
   */
  const closeDialog = (
    type?: "download" | "bulkTag" | "singleTag",
    _?: React.MouseEvent<HTMLElement>,
    _2?: unknown,
    didSave?: boolean,
    tagCount?: number
  ) => {
    setDialog("");
    if (type === "download") {
      track({
        name: "Export Modal Action",
        action: didSave ? "Export" : "Cancel",
        slug: eventSlug,
        totalItems: !!selectedIdsOnPage.length,
      });
    } else
      track({
        name: "Tag Modal Action",
        action: didSave ? "Save" : "Cancel",
        slug: eventSlug,
        totalItems: selectedIdsOnPage.length,
        ...(didSave && { totalTags: tagCount }),
      });
    if (didSave) {
      setSelectedIds(
        selectedIds.filter((id) => !selectedIdsOnPage.includes(id))
      );
    }
    if (type === "singleTag") {
      setSelectedIds([]);
    }
  };

  /**
   * Toggle the dialog for exporting or tagging quesitons
   */
  const openDialog = (type: "download" | "bulkTag" | "singleTag") => {
    setDialog(type);
    trackOpenDialog(type, apiParams, eventSlug, "Questions List");
  };

  const noResultsData = getNoResultsProps(apiParams);

  const downloadProps = {
    active: true,
    action: () => openDialog("download"),
    tooltip: "Export to CSV",
  };
  const tagProps = {
    active: !!selectedIdsOnPage.length,
    action: () => openDialog("bulkTag"),
    tooltip: "Add a Tag",
  };

  const paginateProps = {
    onChangePage: handlePageChange,
    onChangeRows: handleRowsChange,
    count: count,
    page: parseInt(apiParams.page as string),
    rowsPerPage: parseInt(apiParams[URL_PARAMS.pageSize] as string),
    rowOptions: ROW_OPTIONS,
  };

  // searchbar props leveraging useSearchFns
  const searchBarProps = {
    ...searchFns,
    name: "query",
    placeholder: "Search for a question",
  };

  // toolbar dropdown filter list
  const collapseContent = <FilterList />;

  const toolbarCollapse = {
    Icon: FilterListIcon,
    collapseContent,
  };

  const {
    data: allQuestionsData,
    isLoading: isAllQuestionsDataLoading,
    isError,
  } = useGetQuestionsQuery({
    eventSlug,
    params: {},
  });

  // show error snackbar on api fail
  useSuccessErrorSnacks({
    errorMsg: "Failed to load questions, please re-load the page.",
    isError,
  });

  if (
    !isAllQuestionsDataLoading &&
    (allQuestionsData as QuestionsApiResponse)?.count === 0
  ) {
    return (
      <Text
        variant="body3"
        emphasis="medium"
        data-testid="questions-empty-text"
      >
        Once your shareholders submit questions, they will be displayed here.
      </Text>
    );
  }

  return (
    <Stack gap={6}>
      <Text variant="subtitle1">Questions</Text>
      <QuestionsAddTagsDialog
        eventSlug={eventSlug}
        questionIds={selectedIdsOnPage}
        handleClose={closeDialog}
        open={dialog === "bulkTag" || dialog === "singleTag"}
        dialog={dialog}
      />
      <ExportQuestions
        open={dialog === "download"}
        handleClose={closeDialog}
        questionIds={selectedIdsOnPage}
      />
      <Table
        isFetching={isFetching}
        isLoading={isLoading}
        rows={rowData}
        memoCells
        toolbarInside
        columnData={columnData}
        searchBarProps={searchBarProps}
        toolbarCollapse={toolbarCollapse}
        tableMinWidth={1198}
        testid="questions-table"
        tableLayout="auto"
        selectedTitle={pluralize(
          "Question",
          "Questions",
          selectedIdsOnPage.length
        )}
        title={`${count} ${pluralize("Question", "Questions", count)}`}
        columns={COLUMNS}
        downloadProps={downloadProps}
        tagProps={tagProps}
        paginateProps={paginateProps}
        count={count}
        noResultsData={noResultsData}
        toggleAll={handleToggleAllRows}
        numChecked={selectedIdsOnPage.length}
      />
    </Stack>
  );
};

export default QuestionsTable;

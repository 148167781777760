import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { eventReducer } from "./event";
import { commonReducer } from "./common";
import { messageComposeReducer } from "./messageCompose";
import { rootApi } from "./rootApi";
import { staticJsonApi } from "./staticJson";
import { ssoUserApi } from "./ssoUser";
import { questionCategoriesApi } from "./questionCategories";
import { appConfigApi } from "./appConfig";

/**
 * Every new reducer (slice) needs to be added here
 * Currently have 1
 */
/* istanbul ignore next */
export const store = configureStore({
  reducer: {
    event: eventReducer,
    common: commonReducer,
    messageCompose: messageComposeReducer,
    [rootApi.reducerPath]: rootApi.reducer,
    [appConfigApi.reducerPath]: appConfigApi.reducer,
    [questionCategoriesApi.reducerPath]: questionCategoriesApi.reducer,
    [staticJsonApi.reducerPath]: staticJsonApi.reducer,
    [ssoUserApi.reducerPath]: ssoUserApi.reducer,
  },
  /**
   * Only unique / non-injected middleware should be added here
   */
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(rootApi.middleware)
      .concat(appConfigApi.middleware)
      .concat(questionCategoriesApi.middleware)
      .concat(staticJsonApi.middleware)
      .concat(ssoUserApi.middleware),
});

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

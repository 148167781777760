import axios from "axios";
import { ErrorOption } from "react-hook-form";
import { useState } from "react";
import { logout } from "../../../api/adapter";
import { setSnackbar } from "../../../store/common";
import { IAudience } from "../../../types/Events";
import { useAppDispatch } from "../../../store/hooks";
import { eventApi, useDeleteCsvMutation } from "../../../store/event";
import { refreshTokenIfExpired } from "@asayinc/component-library";

interface IResponse {
  data: {
    audience: IAudience;
  };
}

/**
 * Post csv and return a url path of where the csv lives
 */
const postCSV = async (csv: File | Blob, slug: string): Promise<IAudience> => {
  try {
    const token = await refreshTokenIfExpired(logout);
    const {
      data: { audience },
    }: IResponse = await axios.post(
      `${process.env.REACT_APP_CONSUMER_API}v3/portal/qa-events/${slug}/audience/`,
      {
        audience: csv,
      },
      {
        headers: {
          Authorization: `JWT ${token}`,
          "content-type": "multipart/form-data",
        },
      }
    );

    return audience;
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    throw (e as any)?.response?.data?.detail;
  }
};
/**
 * hook which returns a function to post a csv, hook handles error scenarios and loading state
 */
export const useUploadCsvQuery = (isDraft?: boolean) => {
  const [deleteCsvApi] = useDeleteCsvMutation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  // remove csv from event
  const deleteCsv = async (slug: string) => {
    try {
      setIsLoading(true);
      await deleteCsvApi(slug).unwrap();
      return null;
    } catch {
      // set snackbar and error under input if fails
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Failed to remove CSV, please refresh and try again",
        })
      );
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  // add csv to event
  const postCsvQuery = async (
    newAudience: IAudience,
    slug: string,
    setError: (
      name: string,
      error: ErrorOption,
      options?:
        | {
            shouldFocus: boolean;
          }
        | undefined
    ) => void
  ) => {
    setIsLoading(true);
    try {
      const audience = await postCSV(newAudience.file, slug);
      if (isDraft) {
        dispatch(eventApi.util.invalidateTags([{ type: "Event", id: slug }]));
      }
      return audience;
    } catch (e) {
      // set snackbar and error under input if fails
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message:
            "Error: Make sure your file is formatted correctly, and that each row is complete.",
        })
      );
      setError("audience", {
        type: "custom",
        message:
          "Error: Make sure your file is formatted correctly, and that each row is complete.",
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return { postCsvQuery, isLoading: isLoading, deleteCsv };
};

import { MinMaxForm } from "@asayinc/component-library";
import {
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useWatch } from "react-hook-form";
//Mui
import { SelectChangeEvent } from "@mui/material";
// filters
import {
  MessagesReceived,
  QAsParticipatedFilter,
  ShareholderTagsFilter,
  MoreWaysToTarget,
  SharesStatusFilter,
} from "./Filters";
import { LocationCityFilter } from "../../../Common";
// constants
import { FILTER_MESSAGING_MAP } from "../../../../constants";
import { FORM_PATH, MORE_FILTER_OPTIONS } from "./constants";
// hooks
import { useFilterTracking } from "./useFilterTracking";

interface IProps {
  isLoading?: boolean;
  moreFiltersEnabled?: boolean;
  showStatusFilter?: boolean;
}

type Handle<T> = T extends ForwardRefExoticComponent<RefAttributes<infer T2>>
  ? T2
  : never;

const RecipientFilters = ({
  isLoading,
  moreFiltersEnabled,
  showStatusFilter,
}: IProps) => {
  const [activeMoreFilter, setActiveMoreFilter] = useState<null | string>(null);
  useFilterTracking();
  let childHandle: Handle<typeof MessagesReceived>;
  const clearState = useCallback(() => setActiveMoreFilter(null), []);

  // filters need to be ordered so first set to a list along with the field to check in the form
  const filters = useMemo(
    () => [
      {
        displayFilter: true,
        field: `${FORM_PATH}.sharesOwned`,
        component: (
          <MinMaxForm
            key="sharesOwned"
            withinForm={{
              minName: "range.gte",
              maxName: "range.lte",
              fieldName: `${FORM_PATH}.sharesOwned`,
            }}
            chipLabel="Shares owned"
            chipProps={{
              disabled: isLoading,
            }}
            {...FILTER_MESSAGING_MAP.sharesOwned}
          />
        ),
      },
      {
        displayFilter: true,
        field: `${FORM_PATH}.tags`,
        component: (
          <ShareholderTagsFilter key="shareholderTags" isLoading={isLoading} />
        ),
      },
      {
        displayFilter: showStatusFilter,
        field: `${FORM_PATH}.sharesStatus`,
        component: (
          <SharesStatusFilter key="sharesStatus" isLoading={isLoading} />
        ),
      },
      {
        displayFilter: true,
        field: `${FORM_PATH}.state`,
        component: <LocationCityFilter key="location" isLoading={isLoading} />,
      },
      {
        displayFilter: true,
        field: `${FORM_PATH}.qasParticipated`,
        component: (
          <QAsParticipatedFilter key="qasParticipated" isLoading={isLoading} />
        ),
      },
      {
        displayFilter: activeMoreFilter === "messagesReceived",
        field: `${FORM_PATH}.messagesReceived`,
        component: (
          <MessagesReceived
            key="messagesReceived"
            isLoading={isLoading}
            onClose={clearState}
            ref={
              activeMoreFilter === "messagesReceived"
                ? (c) =>
                    (childHandle = c as {
                      triggerPopover: () => void;
                    })
                : undefined
            }
          />
        ),
      },
      {
        displayFilter: activeMoreFilter === "messagesOpened",
        field: `${FORM_PATH}.messagesOpened`,
        component: (
          <MinMaxForm
            key="messagesOpened"
            withinForm={{
              minName: "range.gte",
              maxName: "range.lte",
              fieldName: `${FORM_PATH}.messagesOpened`,
            }}
            chipProps={{
              disabled: isLoading,
            }}
            ref={
              activeMoreFilter === "messagesOpened"
                ? (c) =>
                    (childHandle = c as {
                      triggerPopover: () => void;
                    })
                : undefined
            }
            isPercent
            onClose={clearState}
            chipLabel="Open rate"
            {...FILTER_MESSAGING_MAP.messagesOpened}
          />
        ),
      },
      {
        displayFilter: activeMoreFilter === "messagesLinksClicked",
        field: `${FORM_PATH}.messagesLinksClicked`,
        component: (
          <MinMaxForm
            key="messagesLinksClicked"
            withinForm={{
              minName: "range.gte",
              maxName: "range.lte",
              fieldName: `${FORM_PATH}.messagesLinksClicked`,
            }}
            chipProps={{
              disabled: isLoading,
            }}
            ref={
              activeMoreFilter === "messagesLinksClicked"
                ? (c) =>
                    (childHandle = c as {
                      triggerPopover: () => void;
                    })
                : undefined
            }
            isPercent
            onClose={clearState}
            chipLabel="Click rate"
            {...FILTER_MESSAGING_MAP.messagesLinksClicked}
          />
        ),
      },
      {
        displayFilter: activeMoreFilter === "messagesReplied",
        field: `${FORM_PATH}.messagesReplied`,
        component: (
          <MinMaxForm
            key="messagesReplied"
            withinForm={{
              minName: "range.gte",
              maxName: "range.lte",
              fieldName: `${FORM_PATH}.messagesReplied`,
            }}
            chipProps={{
              disabled: isLoading,
            }}
            ref={
              activeMoreFilter === "messagesReplied"
                ? (c) =>
                    (childHandle = c as {
                      triggerPopover: () => void;
                    })
                : undefined
            }
            isPercent
            onClose={clearState}
            chipLabel="Reply rate"
            {...FILTER_MESSAGING_MAP.messagesReplied}
          />
        ),
      },
    ],
    [isLoading, activeMoreFilter]
  );

  // create list of field names
  const fieldList = useMemo(() => filters.map((filter) => filter.field), []);

  // get all filters by the list of field names
  const filterValueList = useWatch({
    name: fieldList,
  });
  // get filters currently active
  const activeList = useMemo(
    () =>
      filterValueList.reduce((acc, cur, idx) => {
        if (
          cur?.range?.lte ||
          cur?.range?.gte ||
          cur?.range?.lte?.toString() === "0" ||
          cur?.range?.gte?.toString() === "0" ||
          cur?.values?.length
        ) {
          acc.push(fieldList[idx]);
        }
        return acc;
      }, []),
    [filterValueList]
  );

  // more filters that arent currently active
  const inactiveMoreFilters = useMemo(
    () =>
      MORE_FILTER_OPTIONS.filter(
        (f) => !activeList.includes(`${FORM_PATH}.${f.value}`)
      ),
    [activeList]
  );

  // order active filters
  const orderedFilters = useMemo(
    () =>
      filters
        .filter((f) => f.displayFilter || activeList.includes(f.field))
        .sort(
          (a, b) =>
            Number(activeList.includes(b.field)) -
            Number(activeList.includes(a.field))
        ),
    [activeList, filters]
  );

  /**
   * When active filter changes, trigger the popover for the filter
   * this doesnt occur in the onChange function because react needs time to set the ref
   */
  useEffect(() => {
    if (activeMoreFilter) {
      childHandle?.triggerPopover();
    }
  }, [activeMoreFilter]);

  // set the currently selected additional filter
  const handleMoreChange = (e: SelectChangeEvent<unknown>) => {
    setActiveMoreFilter(e.target.value as string);
  };

  return (
    <>
      {orderedFilters.map((filter) => filter.component)}
      {inactiveMoreFilters.length && moreFiltersEnabled && (
        <MoreWaysToTarget
          handleMoreChange={handleMoreChange}
          inactiveMoreFilters={inactiveMoreFilters}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default RecipientFilters;

import { Navigate } from "react-router-dom";
import { Text, isLoggedIn } from "@asayinc/component-library";
import {
  IssuerSummaryCards,
  NoResults,
  QAInsights,
  QAOverTime,
} from "./components";
import { CardWrapper } from "../../components/Common";
import { Stack } from "@mui/material";
import { useEffect } from "react";
import { useGetSentimentTrendsQuery } from "../../store/eventStats";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const HomePage = (): JSX.Element => {
  const isUserLoggedIn = isLoggedIn();
  const { data, isLoading } = useGetSentimentTrendsQuery(
    isUserLoggedIn ? undefined : skipToken
  );

  useEffect(() => {
    document.title = "Say Issuer Portal";
  }, []);

  if (!isUserLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Stack
      px={10}
      pb={10}
      width="100%"
      maxWidth={1440}
      m="0 auto"
      data-testid="homepage"
    >
      <Text mt={10} variant="h4">
        Home
      </Text>
      <IssuerSummaryCards />
      <Text variant="subtitle1" mb={6}>
        Shareholder trends
      </Text>
      {isLoading || (data?.graphData?.length && data?.graphData?.length > 1) ? (
        <Stack gap={8} minWidth={1240}>
          <CardWrapper cardName="qa-insights" sx={{ px: 8, pb: 8, pt: 7 }}>
            <QAInsights />
          </CardWrapper>
          <CardWrapper cardName="qa-over-time">
            <QAOverTime />
          </CardWrapper>
        </Stack>
      ) : (
        <NoResults count={data?.graphData?.length || 0} />
      )}
    </Stack>
  );
};

export default HomePage;

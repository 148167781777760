import {
  BaseSyntheticEvent,
  useCallback,
  useMemo,
  useEffect,
  useState,
} from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FieldValues, useForm, useFormContext } from "react-hook-form";
// components
import { Dialog, IButtonAction } from "@asayinc/component-library";
import { IImportantLink } from "../../../../../../store/messageCompose";
import ImportantLinksForm from "../ImportantLinksForm/ImportantLinksForm";
// rtk query / data
import { useGetSEOMetaDataQuery } from "../../../../../../store/messageCompose";
import {
  postImage,
  retrieveImage,
  setSnackbar,
} from "../../../../../../store/common";
import { useSuccessErrorSnacks } from "../../../../../../hooks/useSuccessErrorSnacks";
import { generateRandomString } from "../../../../../../utils/generateRandomString";
import { useAppDispatch } from "../../../../../../store/hooks";

interface IProps {
  open: boolean;
  closeDialog: () => void;
  addNew?: boolean;
  link: IImportantLink;
  idx: number;
  saveOrAddLink: (add?: boolean) => void;
  isDisabled?: boolean;
}

const ImportantLinksDialog = ({
  open,
  closeDialog,
  link,
  idx,
  addNew,
  saveOrAddLink,
  isDisabled,
}: IProps) => {
  const dispatch = useAppDispatch();
  const {
    control,
    register,
    reset,
    handleSubmit,
    setValue: setLinkValue,
    setError,
    formState: { isDirty, isValid, errors },
    trigger,
  } = useForm({
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: "onTouched",
  });

  const handleClose = () => {
    closeDialog();
    if (addNew) {
      reset(link);
    }
  };

  const { setValue } = useFormContext();

  const hasError = Object.keys(errors).length > 0;

  // location and index this field is in the form
  const locationInForm = `content.importantLinks.${idx}`;
  // if no errors and the appropriate amount of fields are dirty, we can save
  const canSave = (!hasError || isValid) && isDirty;

  /**
   * When saving we replace the existing state of the link with the new values from the edit state
   * Then call parent function to hit api and update with a patch or post
   */
  const save = useCallback(
    async (values: FieldValues) => {
      try {
        let { image = "", imageFileName } = values;
        // check if image has been uploaded already
        if (
          image.length &&
          image.indexOf(process.env.REACT_APP_ASSETS) === -1
        ) {
          // retrieve image from source and upload
          const imageFile = await retrieveImage(
            `${image}?${generateRandomString()}`
          );
          const {
            data: { filename, url },
          } = await postImage(imageFile, imageFile.name, "asset");

          image = url;
          imageFileName = filename;
        }

        // use updated image values in outer form
        const updatedValues = { ...values, image, imageFileName };
        setValue(locationInForm, updatedValues, { shouldDirty: true });

        // callback used for analytics
        saveOrAddLink(addNew);

        handleClose();
      } catch {
        setLinkValue("image", undefined, { shouldValidate: true });
        dispatch(
          setSnackbar({
            message: `Failed to upload, please re-select image.`,
            open: true,
            severity: "error",
          })
        );
      }
    },
    [setValue, locationInForm]
  );

  const actions = [
    {
      disabled: !canSave || isDisabled,
      label: "Save",
      type: "submit",
    },
  ] as IButtonAction[];

  // have to memoize so that the form doesn't rerender on every change
  const WrapperForm = ({ children }: { children?: React.ReactNode }) => (
    <form onSubmit={handleSubmit(save)}>{children}</form>
  );
  const MemodForm = useMemo(
    () => WrapperForm,
    [handleSubmit, idx, setLinkValue, isDisabled]
  );

  // onBlur of URL input, retrieve SEO metadata and autofill remaining fields
  const [formUrl, setFormUrl] = useState<null | string>(null);
  const {
    data: metadata,
    isLoading,
    isError,
  } = useGetSEOMetaDataQuery(formUrl ? formUrl : skipToken);

  // ensure image can be pulled and set form fields
  const setMetaData = async () => {
    if (metadata) {
      const { title, description, image } = metadata;
      if (image) {
        setLinkValue("image", image);
        setLinkValue("title", title);
        setLinkValue("description", description);
      }
    }
  };

  useEffect(() => {
    setMetaData();
  }, [metadata]);

  useSuccessErrorSnacks({
    errorMsg: "Failed to fetch. Please check the entered URL and try again.",
    isError,
  });

  const handleBlur = useCallback(async (event: BaseSyntheticEvent) => {
    let url = event.target.value;

    if (!/^https?:\/\//i.test(url)) {
      url = "https://" + url;
      setLinkValue("url", url);
    }

    // if URL is not valid, do not call setUrl / request SEO data
    const validationResult = await trigger("url");
    if (!validationResult) {
      return;
    }

    // if URL is a PDF, do not call setUrl / request SEO data and set image
    const extension = url.split(".").pop();
    const isPdf = extension.toLowerCase() === "pdf";
    if (isPdf) {
      setLinkValue(
        "image",
        "https://assets.say.rocks/images/ir-portal/pdf-preview.png"
      );
      setLinkValue("imageFileName", "pdf-preview.png");
      return;
    }

    setFormUrl(url);
  }, []);

  return (
    <Dialog
      data-testid="important-links-dialog"
      open={open}
      title="Important Links"
      handleClose={handleClose}
      CustomWrapper={MemodForm}
      buttonActions={actions}
      content={
        <ImportantLinksForm
          control={control}
          register={register}
          idx={idx}
          setLinkValue={setLinkValue}
          setError={setError}
          link={link}
          isDisabled={isDisabled || isLoading}
          handleBlur={handleBlur}
        />
      }
    />
  );
};

export default ImportantLinksDialog;

import { useEffect, useState } from "react";
import { setSnackbar } from "../store/common";
import { useAppDispatch } from "../store/hooks";

interface IProps {
  successMsg?: string;
  errorMsg?: string;
  errorAction?: () => void;
  successAction?: () => void;
  isError?: boolean;
  isSuccess?: boolean;
  autoHideDuration?: number;
}

/**
 * Display provided success or error message on api complete
 * Run actions if provided for success and error scenarios
 */
export const useSuccessErrorSnacks = ({
  successMsg,
  errorMsg,
  errorAction,
  successAction,
  isSuccess,
  isError,
  autoHideDuration = 4000,
}: IProps): void => {
  const dispatch = useAppDispatch();
  const [isInitialRender, setIsInitialRender] = useState(true);
  useEffect(() => {
    if (!isInitialRender) {
      if (isSuccess && successMsg) {
        dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: successMsg,
            autoHideDuration,
          })
        );
        if (successAction) {
          successAction();
        }
      } else if (isError && errorMsg) {
        dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: errorMsg,
            autoHideDuration,
          })
        );
        if (errorAction) {
          errorAction();
        }
      }
    } else {
      setIsInitialRender(false);
    }
  }, [isSuccess, isError]);
};

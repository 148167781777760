/* istanbul ignore file */
import axios, { AxiosRequestTransformer } from "axios";
import humps from "humps";
import { router } from "../router";
import {
  REFRESH_TOKEN,
  refreshTokenIfExpired,
  removeAuthToken,
} from "@asayinc/component-library";

// clear local storage and auth token
export const logout = () => {
  removeAuthToken();
  window.localStorage.removeItem(REFRESH_TOKEN);
  router.navigate("/login", { replace: true });
};

/**
 * converts my_var to myVar and back for requests/responses.
 * Covers any and all responses/requests. no type needed
 */
const sharedAxiosConfiguration = {
  transformRequest: [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: any) => humps.decamelizeKeys(data),
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosRequestTransformer[]),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: any) => humps.camelizeKeys(data),
  ],
};

// SSO
const ssoApi = axios.create({
  ...sharedAxiosConfiguration,
  baseURL: `${process.env.REACT_APP_SSO_API}v1/`,
});
// Consumer
const protectedConsumerApi = axios.create({
  ...sharedAxiosConfiguration,
  baseURL: `${process.env.REACT_APP_CONSUMER_API}v3/portal/`,
});

protectedConsumerApi.interceptors.request.use(
  async (config) => {
    const token = await refreshTokenIfExpired(logout);
    config.headers = {
      ...config.headers,
      Authorization: `JWT ${token}`,
      ...(!!window.localStorage.getItem("company-override")
        ? {
            "company-override": window.localStorage.getItem(
              "company-override"
            ) as string,
          }
        : {}),
    };
    return config;
  },
  (error) => Promise.reject(error)
);

export { ssoApi, protectedConsumerApi };

/* istanbul ignore file */

import { Navigate, useLocation, Outlet } from "react-router-dom";
import { Buffer } from "buffer";
import { SegmentAnalytics } from "@segment/analytics.js-core";
import { usePageViews } from "./hooks";
import { setLastPage } from "./store/common";
import { useAppDispatch } from "./store/hooks";
import { isLoggedIn } from "@asayinc/component-library";

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

window.Buffer = window.Buffer || Buffer;

const App = (): JSX.Element => {
  usePageViews();
  const { pathname, search } = useLocation();
  const dispatch = useAppDispatch();
  const isNotLoggedIn = !isLoggedIn();

  // removes double slashes so we can use dynamic routing
  if (pathname.match("/.*/$")) {
    return (
      <Navigate
        replace
        to={{
          pathname: pathname.replace(/\/+$/, ""),
          search,
        }}
      />
    );
  }

  // navigate user to login if they dont have an auth token
  if (
    isNotLoggedIn &&
    pathname !== "/login" &&
    pathname !== "/forgot-password" &&
    pathname !== "/reset-password"
  ) {
    dispatch(setLastPage(pathname));
    return (
      <Navigate
        replace
        to={{
          pathname: "/login",
        }}
      />
    );
  }

  return <Outlet />;
};

export default App;

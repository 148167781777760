import dayjs from "dayjs";
import { useMemo } from "react";
// components
import { Stack } from "@mui/material";
import { nFormatter, Text } from "@asayinc/component-library";
// redux / data
import {
  useGetAllShareholderStatsQuery,
  useGetPercentileShareholderTrendsQuery,
  useGetTopShareholderTrendsQuery,
} from "../../../../../../../store/allShareholderStats";
// types
import {
  Carousel,
  StatCardVariant2,
} from "../../../../../../../components/Common";
import { SwiperSlide } from "swiper/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useIsShareholdersEnabled } from "../../../../../useIsShareholdersEnabled";

const ShareholderGroups = () => {
  // get stat data
  const { data: stats, isLoading: isStatsLoading } =
    useGetAllShareholderStatsQuery({
      aggregators: "sum",
    });

  const { isFeatureEnabled } = useIsShareholdersEnabled();

  const { data: topData, isLoading: isTopShareholderLoading } =
    useGetTopShareholderTrendsQuery(isFeatureEnabled ? {} : skipToken);
  const { data: percentileData, isLoading: isPercentileShareholderLoading } =
    useGetPercentileShareholderTrendsQuery(
      isFeatureEnabled ? { percentile: "p99,p90,p75" } : skipToken
    );
  const isLoading =
    isTopShareholderLoading || isPercentileShareholderLoading || isStatsLoading;

  const cardData = useMemo(() => {
    const dateString = dayjs(topData?.top50?.[0]?.recordDate).format("MMMM D");

    // number of shares in top 50 and their percent of the total shares
    const top50Shares = topData?.top50?.[0]?.sharesOwned || 0;
    const top50PercentOfTotal = (top50Shares / (stats?.sum || 1)) * 100;
    // number of shares in top 100 and their percent of the total shares
    const top100Shares = topData?.top100?.[0]?.sharesOwned || 0;
    const top100PercentOfTotal = (top100Shares / (stats?.sum || 1)) * 100;
    // number of shares in top 500 and their percent of the total shares
    const top500Shares = topData?.top500?.[0]?.sharesOwned || 0;
    const top500PercentOfTotal = (top500Shares / (stats?.sum || 1)) * 100;

    return [
      {
        title: "Top 50",
        id: "50",
        tooltipText: `Top 50 shareholders by shares owned as of ${dateString}`,
        leftStatTitle: "Shares owned",
        leftStat: nFormatter(top50Shares),
        rightStatTitle: "% of total",
        rightStat: `${nFormatter(top50PercentOfTotal)}%`,
      },
      {
        title: "Top 100",
        id: "100",
        tooltipText: `Top 100 shareholders by shares owned as of ${dateString}`,
        leftStatTitle: "Shares owned",
        leftStat: nFormatter(top100Shares),
        rightStatTitle: "% of total",
        rightStat: `${nFormatter(top100PercentOfTotal)}%`,
      },
      {
        title: "Top 500",
        id: "500",
        tooltipText: `Top 500 shareholders by shares owned as of ${dateString}`,
        leftStatTitle: "Shares owned",
        leftStat: nFormatter(top500Shares),
        rightStatTitle: "% of total",
        rightStat: `${nFormatter(top500PercentOfTotal)}%`,
      },
      {
        title: "Top 1%",
        id: "1%",
        isPercentCard: true,
        tooltipText: `Top 1% shareholders by shares owned as of ${dateString}`,
        leftStatTitle: "Shares owned",
        leftStat: nFormatter(
          percentileData?.p99?.sharesOwnedMean?.data?.[0]?.value || 0
        ),
        rightStatTitle: "Shareholder count",
        rightStat: nFormatter(
          percentileData?.p99?.customerCountMean?.data?.[0]?.value || 0
        ),
      },
      {
        title: "Top 10%",
        id: "10%",
        isPercentCard: true,
        tooltipText: `Top 10% shareholders by shares owned as of ${dateString}`,
        leftStatTitle: "Shares owned",
        leftStat: nFormatter(
          percentileData?.p90?.sharesOwnedMean?.data?.[0]?.value || 0
        ),
        rightStatTitle: "Shareholder count",
        rightStat: nFormatter(
          percentileData?.p90?.customerCountMean?.data?.[0]?.value || 0
        ),
      },
      {
        title: "Top 25%",
        isPercentCard: true,
        id: "25%",
        tooltipText: `Top 25% shareholders by shares owned as of ${dateString}`,
        leftStatTitle: "Shares owned",
        leftStat: nFormatter(
          percentileData?.p75?.sharesOwnedMean?.data?.[0]?.value || 0
        ),
        rightStatTitle: "Shareholder count",
        rightStat: nFormatter(
          percentileData?.p75?.customerCountMean?.data?.[0]?.value || 0
        ),
      },
    ];
  }, [isLoading]);

  return (
    <Stack>
      <Text variant="subtitle1" mb={6}>
        Shareholder groups & trends
      </Text>
      <Carousel>
        {cardData.map((data) => (
          <SwiperSlide key={data.id}>
            <StatCardVariant2
              key={data.title}
              {...data}
              iconName="PeopleFilled"
              cardName="shareholder-group"
              isLoading={isLoading}
              isFeatureEnabled={isFeatureEnabled}
            />
          </SwiperSlide>
        ))}
      </Carousel>
    </Stack>
  );
};

export default ShareholderGroups;

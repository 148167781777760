import { Box, Stack, SxProps } from "@mui/material";
import {
  IconName,
  SayIcon,
  Text,
  TooltipInfoIcon,
} from "@asayinc/component-library";
import { CardWrapper } from "../../Templates";
import { BLURRY } from "../../../../constants";

interface IProps {
  title: string;
  tooltipText: string;
  leftStatTitle: string;
  leftStat: string;
  rightStatTitle: string;
  rightStat: string;
  isPercentCard?: boolean;
  isLoading: boolean;
  cardName: string;
  isFeatureEnabled?: boolean;
  iconName?: IconName;
  icon?: React.ReactNode;
  sx?: SxProps;
}

const ShareholderGroupCard = ({
  cardName,
  title,
  tooltipText,
  leftStatTitle,
  leftStat,
  rightStatTitle,
  rightStat,
  isPercentCard,
  isLoading,
  isFeatureEnabled = true,
  iconName = "PeopleFilled",
  icon,
  sx,
}: IProps) => {
  return (
    <CardWrapper
      cardName={cardName}
      sx={{ minWidth: isPercentCard ? 328 : 269, ...sx }}
    >
      <Stack>
        <Box mb={16}>{icon ? icon : <SayIcon name={iconName} />}</Box>
        <Stack direction="row" alignItems="center" gap={1} mb={6}>
          <Text variant="h5">{title}</Text>
          {isFeatureEnabled && (
            <TooltipInfoIcon title={tooltipText} iconColor="black" />
          )}
        </Stack>
        <Stack direction="row" gap={12}>
          <Stack gap={1}>
            <Text variant="body2">{leftStatTitle}</Text>
            <Text
              variant="subtitle2"
              sx={!isFeatureEnabled ? BLURRY : undefined}
              loadingProps={{
                sx: { width: 50 },
              }}
            >
              {isLoading ? undefined : leftStat}
            </Text>
          </Stack>
          <Stack gap={1}>
            <Text variant="body2">{rightStatTitle}</Text>
            <Text
              variant="subtitle2"
              sx={!isFeatureEnabled ? BLURRY : undefined}
              loadingProps={{
                sx: { width: 50 },
              }}
            >
              {isLoading ? undefined : rightStat}
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </CardWrapper>
  );
};

export default ShareholderGroupCard;
